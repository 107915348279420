import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import LockResetIcon from "@mui/icons-material/LockReset";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
  Popover,
} from "@mui/material";
import { toast } from "react-toastify";
// mocks_
import account from "../../../_mock/account";
import { logout } from "../../../features/AuthSlice";

const MENU_OPTIONS = [
  // { label: 'Home', icon: 'eva:home-fill' },
  // { label: 'Profile', icon: 'eva:person-fill' },
  // { label: 'Settings', icon: 'eva:settings-2-fill' },
];

export default function AccountPopover({ adminDetails }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const email = localStorage.getItem("email");
  const name = localStorage.getItem("name");
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    toast.success("You have been successfully logged out");
    localStorage.clear();
    navigate("/login", { replace: true });
  };
  const handleSetting = () => {
    navigate("/global-setting");
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {adminDetails.name}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {adminDetails?.email}
          </Typography>
        </Box>

        {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

        {/* <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}

        <Divider sx={{ borderStyle: "dashed" }} />
        <MenuItem
          onClick={() => {
            handleClose();
            navigate("/change-password");
          }}
        >
          <LockResetIcon sx={{ mr: 2 }} /> Change Password
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleSetting();
          }}
        >
          <SettingsIcon sx={{ mr: 2 }} /> Settings
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            handleClose();
            handleLogout();
          }}
        >
          <LogoutIcon sx={{ mr: 2 }} /> Logout
        </MenuItem> */}
      </Popover>
    </>
  );
}
