


const account = {
  
  displayName: 'super admin',
  email: 'ludo@mailinator.com',
  photoURL: '/assets/images/avatars/avatar_12.jpg',
};

export default account;
